<template>
  <a-modal :title="modal.title" v-model="modal.visible" @ok="handleOk" @cancel="handleCancel" :width="980">
    <a-form class="flex-form form-label-6" labelAlign="left">
      <div class="grid grid-cols-2">
        <a-form-item label="设备名称">{{ modal.data.equipmentName }}</a-form-item>
        <a-form-item label="设备id" class="break-all">{{ modal.data.equipmentId }}</a-form-item>
        <a-form-item label="项目名称">{{ modal.data.projectName }}</a-form-item>
        <a-form-item label="项目编号">{{ modal.data.projectCode }}</a-form-item>
        <a-form-item label="位置">{{ modal.data.position }}</a-form-item>
        <a-form-item label="方向">{{ modal.data.direction }}</a-form-item>
        <a-form-item label="门禁启用时间">{{ modal.data.createTime }}</a-form-item>
        <a-form-item label="门禁供应商">{{ modal.data.provider }}</a-form-item>
        <!-- <a-form-item label="状态">{{ modal.data.equipmentId }}</a-form-item> -->
      </div>
    </a-form>
    <div class="text-xl font-bold">历史记录</div>
    <a-table
      class="mt-4"
      :rowKey="$useRowIndex"
      :columns="tableColumns"
      :dataSource="dataSource"
      :pagination="false"
      size="small"
    ></a-table>
  </a-modal>
</template>

<script>
import { toolMixin } from '@/utils/mixin'

export default {
  mixins: [toolMixin],
  data() {
    const tableColumns = [
      {
        title: '项目名称',
        width: '15%',
        align: 'center',
        dataIndex: 'projectName',
      },
      {
        title: '项目编码',
        width: '6%',
        align: 'center',
        dataIndex: 'projectCode',
      },

      {
        title: '位置',
        width: '10%',
        align: 'center',
        dataIndex: 'position',
      },
      {
        title: '方向',
        width: '6%',
        align: 'center',
        dataIndex: 'direction',
      },
      {
        title: '门禁启用时间',
        width: '10%',
        align: 'center',
        dataIndex: 'createTime',
      },
      {
        title: '门禁供应商',
        width: '10%',
        align: 'center',
        dataIndex: 'provider',
      },
    ]
    return {
      modal: {
        visible: false,
        // form: this.$form.createForm(this),
        title: '设备详情',
        data: {},
      },
      tableColumns: tableColumns,
      dataSource: [],
    }
  },
  methods: {
    async start(row) {
      this.$reset()
      this.modal.visible = true
      this.modal.data = row.now
      this.dataSource = row.before
    },
    async handleOk() {
      this.$emit('end', 'ok')
    },
    handleCancel() {
      this.$emit('end', 'cancel')
    },
  },
}
</script>
